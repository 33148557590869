<template>
  <div class="market-wrapper">
    <div style="background-color: #F3F4F6;color: var(--grey);" class="market-header d-flex justify-content-between p-2"
      data-toggle="collapse" v-bind:data-target="dataTarget" aria-expanded="false" v-bind:aria-controls="ariaControls">
      <div v-text="market_name"></div>
      <div class="text-light">
        <i class="bi bi-chevron-down"></i>
      </div>
    </div>

    <div class="row">
      <Odd class="col" v-for="o in outcome.outcomes" :key="getKey(o.odd_id)"
        v-bind:id="o.odd_id" :class="getClasses(o)" v-bind:sport_id="fixture.sport_id"
        v-bind:market_name="outcome.market_name" v-bind:home_team="fixture.home_team"
        v-bind:away_team="fixture.away_team" v-bind:pick="o" v-bind:show_direction="show_direction"
        v-bind:match_id="fixture.match_id" v-bind:market_id="outcome.market_id" v-bind:outcome_id="o.outcome_id"
        v-bind:probability="o.probability" v-bind:specifier="outcome.specifier" v-bind:active="o.active"
        v-bind:status="outcome.status" v-bind:odds="o.odds" v-bind:alias="o.alias"
        v-bind:producer_id="fixture.producer_id" v-bind:producer_status="fixture.producer_status"
        v-bind:previous_odds="o.previous_odds" />
    </div>
  </div>
</template>

<style scoped>
  .row {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    margin: -2px;
  }

  .col {
    flex: 1 1 calc(33.333% - 4x);
    margin-bottom: 6px;
    margin-right: 6px;
    margin-left: 6px;
    padding: 0;
  }
</style>


<script>

import Odd from './Odd'

export default {
  components: {
    Odd
  },

  name: "Outcome",
  props: {
    outcome: {
      type: Object,
      required: true
    },
    market_id: {
      required: true
    },
    market_name: {
      required: true
    },
    totals_238: {
      //type: Array,
      required: true
    },
    totals_18: {
      //type: Array,
      required: true
    },
    totals_166: {
      //type: Array,
      required: true
    },
    fixture: {
      type: Object,
      required: true
    },
  },
  methods: {
    isLocked: function (o) {

      if (parseInt(o.producer_id) === 1) {

        return parseInt(o.status) > 0 || parseInt(o.active) === 0 || parseInt(o.producer_status) === 0;

      } else {

        return parseInt(o.status) > 0 || parseInt(o.active) === 0;

      }
    },
    getKey: function (index) {

      return Math.random().toString(10).replace('0.', 'specifiers-id-' + index + '-');

    },
    /**
     *
     * @param sport_id
     * @param match_id
     * @param market_name
     * @param market_id
     * @param competitor_1
     * @param competitor_2
     * @param picks
     */
    pick: function (sport_id, match_id, market_name, market_id, competitor_1, competitor_2, picks) {

      var vm = this;

      console.log('addPick match_id=>' + match_id + ' market_name =>' + market_name + ' market_id=>' + market_id + ' picks=>' + JSON.stringify(picks));

      var odd_id = picks.odd_id;
      var bSlip = this.getObject("bslip");

      if (!Array.isArray(bSlip)) {

        bSlip = [];
      }

      var isunselect = false;
      picks.match_id = match_id;
      picks.sport_id = sport_id;
      picks.market_name = market_name;
      picks.market_id = market_id;
      picks.home_team = competitor_1;
      picks.away_team = competitor_2;

      if (!bSlip || bSlip === '' || bSlip === "") {

        bSlip = [];
      }

      if (bSlip.length > 0) {

        vm.jQuery.each(bSlip, function (k, v) {

          if (v !== undefined && v !== null) {

            if (v.odd_id === odd_id) {

              isunselect = true;
            }

            if (v.match_id === match_id) {

              delete bSlip[k];
            }
          }
        });
      }

      if (!isunselect) {

        bSlip.push(picks);
      }

      var bt = [];

      vm.jQuery.each(bSlip, function (k, v) {

        if (v !== undefined && v) {

          bt.push(v);
        }

      });

      this.saveObject("bslip", bt);
      this.autoRefreshUI(this.$vnode.tag);

    },
    getOddDirectionClass: function (outcome) {

      if (parseInt(outcome.active) === 0 || parseInt(outcome.status) !== 0) {

        return '';
      }

      if (parseFloat(outcome.odd) >= parseFloat(outcome.previous_odds)) {

        return 'arrow green up';

      }

      return 'arrow red down';

    },

    getClasses: function (o) {

      //var st = parseInt(o.active) === 0 || parseInt(o.status) !== 0 ? 'locked btn-odd' : 'btn-odd';

      return "btn-odd small-txt mr-1 mt-1 " +
        " match-" + this.fixture.match_id +
        " producer-" + o.producer_id +
        " market-" + this.market_id +
        " outcome-" + o.outcome_id +
        " specifier-" + o.specifier;

    },

  },
  mounted: function () {

    /*
    var vm = this;

    this.EventBus.$on('odds:'+vm.id,function(payload){

      vm.oddStatus = payload.status;
      vm.oddActive = payload.active;

      if(parseFloat(payload.odd) !== parseFloat(vm.odd)) {

        vm.previousOdds = vm.odd;
        vm.odd = payload.odd;
      }

      vm.locked = parseInt(vm.oddStatus) > 0 || parseInt(vm.oddActive) === 0 || parseInt(vm.producerStatus) === 0;
      vm.getStatusClass();

      vm.directionClass = vm.getOddDirectionClass();

    });

    this.EventBus.$on('producer:status',function(payload){

      var producer_id = payload.producer_id;
      var producer_status = payload.producer_status;

      if(parseInt(producer_id) === 1 ) {

        vm.producerStatus = producer_status;
        vm.directionClass = vm.getOddDirectionClass();
        vm.locked = parseInt(vm.oddStatus) > 0 || parseInt(vm.oddActive) === 0 || parseInt(vm.producerStatus) === 0;
        vm.getStatusClass();
      }

    });
    */

  },
  computed: {
    dataTarget: function () {
      return "#collapseThreeway" + this.market_id;
    },
    ariaControls: function () {
      return "collapseThreeway" + this.market_id;
    },
  },
  data: function () {
    return {
      show_direction: false
    }
  }
}
</script>